<template>
  <div class="customCon">
    <div class="top">
      <div class="topBar">
        <div class="titleBox">
          <div class="back" @click='back'>
            <div class="iconfont iconlfArrow"></div>
            <div class="text">返回</div>
          </div>
          <div class="lineBar"></div>
          <div class="title">
            
            <div class="titleText">王某某和张三婚姻财产分配纠纷案</div>
          </div>
        </div>
     
      </div>
      <div class="topNav">
        <div class="navItem">
          <a class='active' href='javascript:;'>详情</a>
        </div>
      </div>
    </div>
    <div class="CaseMainDetails">
      <div class="leftBox">
        <!-- 基本信息 -->
        <div class="itemBox">
          <div class="titleBox">
            <div class="title">基本信息</div>
            <div class="iconBox el-icon-edit-outline"></div>
          </div>
          <div class="contentBox">
            <div class="contentItem">
              <div class="keyTitle">客户名称</div>
              <div class="valueTitle">南京什么有限公司</div>
            </div>
            <div class="contentItem">
              <div class="keyTitle">客户类型</div>
              <div class="valueTitle">机构</div>
            </div>
            <div class="contentItem">
              <div class="keyTitle">客户标签</div>
              <div class="valueTitle">案源客户 顾问单位</div>
            </div>
          </div>
        </div>
        <!-- 联系人信息 -->
      <div class="itemBox">
        <div class="titleBox">
          <div class="title">联系人信息</div>
          <div class="iconBox el-icon-edit-outline"></div>
        </div>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyIcon iconfont icontouxiang"></div>
            <div class="keyTitle">张三</div>
            <div class="valueTitle">业务部经理</div>
            <div class="valueTitle">15261401518</div>
          </div>
          <div class="contentItem">
            <div class="keyIcon iconfont icontouxiang"></div>
            <div class="keyTitle">李四</div>
            <div class="valueTitle">门卫</div>
            <div class="valueTitle">15261401518</div>
          </div>
        </div>
      </div>
      </div>
      <div class="rightBox">
        <!-- 关联案件 -->
      <div class="itemBox">
        <div class="titleBox">
          <div class="title">关联案件</div>
          <div class="iconBox el-icon-edit-outline"></div>
        </div>
        <div class="contentBox">
          <div class="contentUl">
            <div class="contentLi">
              <div class="liTop">
                <div>2020-05-12</div>
                <div>苏永（民）2020 第 101 号</div>
              </div>
              <div class="lititle">王某某和张三婚姻财产分配纠纷案王某某和张三婚姻财产分配纠纷案</div>
            </div>
            <div class="contentStatus">进行中</div>
          </div>
        </div>
      </div>
      <!-- 关联项目 -->
      <div class="itemBox">
        <div class="titleBox">
          <div class="title">关联项目</div>
          <div class="iconBox el-icon-edit-outline"></div>
        </div>
        <div class="contentBox">
          <div class="contentUl">
            <div class="contentLi">
              <div class="liTop">
                <div>2020-05-12</div>
                <div>苏永（民）2020 第 101 号</div>
              </div>
              <div class="lititle">王某某和张三婚姻财产分配纠纷案王某某和张三婚姻财产分配纠纷案</div>
            </div>
            <div class="contentStatus">进行中</div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
export default defineComponent({
  name:"customDetails",
  data(){
    return {
      
    }
  },
  methods:{
    
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/comman.scss';
.customCon{
  width:1200px;
  margin:0 auto;
  background: #fff;
  border-radius: 20px;
  .top{
    .topBar{
      padding:30px;
      border-bottom:1px solid #eee;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .titleBox{
        display: flex;
        align-items: center;
        .back{
          color:#555;
          display: flex;
          align-items: center;
          &:hover{
            cursor: pointer;
            color:$main-color
          }
          .iconlfArrow{
            font-size: 20px;
          }
          .text{
            font-size: 14px;
            margin-left:10px;
          }
        }
        .lineBar{
          width:1px;
          height:20px;
          margin:0 20px;
          border-right: 1px solid #ddd;
        }
        .title{
          display: flex;
          align-items: center;
          
          .titleText{
            font-size: 18px;
            color:#333;
            margin:0 25px;
          }

        }
      }
      
    }
    .topNav{
      display: flex;
      align-items: center;
      padding:20px 0;
      margin:0 30px;
      border-bottom:1px solid #eee;
      .navItem{
        margin-right: 20px;
        a{
          display: block;
          width:90px;
          height:30px;
          line-height: 30px;
          text-align: center;
          border-radius:30px;
          border:1px solid #ddd;
          text-decoration: none;
          font-size: 14px;
          color:#555;
          &:hover{
            cursor: pointer;
            color:$main-color;
            border-color:$main-color;
          }
        }
        .active{
          color:$main-color;
          border-color:$main-color;
        }
      }
    }
  }
  .CaseMainDetails{
    display: flex;
    width:100%;
    box-sizing: border-box;
    padding:0 30px;
    .leftBox{
      border-right:1px solid #eee;
    }
    .leftBox,.rightBox{
      width:50%;
      box-sizing: border-box;
      .itemBox{
        border-bottom:1px solid #eee;
        padding:30px 0;
        margin:0 20px;
        &:last-child{
          border:none;
        }
        .titleBox{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom:30px;
          .title{
            font-size: 16px;
            color:#666666;
          }
          .iconBox{
            font-size: 26px;
            color:$main-color;
            &:hover{
              cursor: pointer;
            }
          }
        }
        .contentBox{
          padding-left:10px;
          .contentItem{
            margin-bottom:30px;
            display: flex;
            align-items: center;
            .keyTitle{
              min-width:56px;
              margin-right: 20px;
            }
            .textRight{
              text-align: right;
            }
            .flex1{
              flex:1;
              line-height: 20px;
            }
            .keyTitle,.valueTitle{
              font-size: 14px;
              color:#777777;
            }
            .valueTitle{
              min-width: 80px;
              margin-right: 20px;
            }
            .keyIcon{
              font-size: 40px;
              color:$main-color;
              margin-right: 30px;
            }
          }
          .contentUl{
            padding:20px;
            width:100%;
            border-radius: 10px;
            box-sizing:border-box;
            box-shadow: 2px 2px 5px rgb(220 223 230);
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom:20px;
            .contentLi{
              width:260px;
              .liTop{
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;
                color:#666;
                margin-bottom:20px;
              }
              .lititle{
                font-size: 14px;
                color:#666;
                width:100%;
                @include ellipsis;
              }
            }
            .contentStatus{
              font-size: 14px;
              color:#666;
            }
          }
        }
      }
    }
    .rightBox{
      padding-left:20px;
      .contentBox{
        .tableBoxCon{
          width:90%;
          display: flex;
          margin:auto;
          margin-bottom:20px;
          .tableTitle{
            font-size: 16px;
            color:#666;
            width:60px;
          }
          .tableBox{
            width:100%;
            .tableItem{
              display: flex;
              width:100%;
              margin-bottom:20px;
              &:last-child{
                margin-bottom:0;
              }
              .tableItemName{
                // width:calc( (100% - 60px) / 2 );
                width:165px;
                font-size: 14px;
                color:#777;
                &:nth-child(2){
                  width:100px;
                }
                &:nth-child(3){
                  width:150px;
                }
              }
            }
          }
        }
      }
    }
  }
}

</style>